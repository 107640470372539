@import "~nhsuk-frontend/packages/nhsuk.scss";

@font-face {
  font-family: "Frutiger LT Std";
  font-style: normal;
  font-weight: 400;
  src: local("Frutiger LT Std"),
    url("./assets/fonts/FrutigerLTStd-Cn.woff") format("woff");
}

@font-face {
  font-family: "Frutiger LT Std";
  font-style: normal;
  font-weight: 400;
  src: local("Frutiger LT Std"),
    url("./assets/fonts/FrutigerLTStd-Roman.woff") format("woff");
}

@font-face {
  font-family: "Frutiger LT Std";
  font-style: normal;
  font-weight: 300;
  src: local("Frutiger LT Std"),
    url("./assets/fonts/FrutigerLTStd-LightCn.woff") format("woff");
}

@font-face {
  font-family: "Frutiger LT Std";
  font-style: normal;
  font-weight: 700;
  src: local("Frutiger LT Std"),
    url("./assets/fonts/FrutigerLTStd-Bold.woff") format("woff");
}

@font-face {
  font-family: "Frutiger LT Std";
  font-style: normal;
  font-weight: 850;
  src: local("Frutiger LT Std"),
    url("./assets/fonts/FrutigerLTStd-Black.woff") format("woff");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Frutiger LT Std", sans-serif;
  font-style: normal;
}

/*****
Overwrite CSS Styles
******/

.nhsuk-back-link {
  margin: 0;
  padding: 0;
}

.nhsuk-form-group:has(.nhsuk-textarea) {
  margin-bottom: 0;
}
.question-answer-character-count {
  text-align: right;
  margin-bottom: 1.5em;
}

// Consistent with nhs.uk
a {
  text-decoration: underline;
}

h1 {
  font-family: "Frutiger LT Std";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

ol.lower-alpha-nhsuk {
  counter-reset: list;
}
ol.lower-alpha-nhsuk > li {
  list-style: none;
}
ol.lower-alpha-nhsuk > li:before {
  content: "(" counter(list, lower-alpha) ") ";
  counter-increment: list;
  font-weight: bold;
}
